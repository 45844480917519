 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="流水编号">
				<el-input class="el_input" v-model="form.ra_num" placeholder="流水编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="操作人类型">
				<el-select class="el_input" v-model="form.act_user_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="自己" value="1"></el-option>
					<el-option label="其他app用户" value="2"></el-option>
					<el-option label="后台人员" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="操作类型">
				<el-select class="el_input" v-model="form.act_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="后台充值" value="1"></el-option>
					<el-option label="支付宝充值" value="2"></el-option>
					<el-option label="微信充值" value="3"></el-option>
					<el-option label="实时单支付" value="4"></el-option>
					<el-option label="补录单支付" value="5"></el-option>
					<el-option label="补录单支付" value="5"></el-option>
					<el-option label="实时单撤销支付" value="6"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="余额类型">
				<el-select class="el_input" v-model="form.balance_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="对公充值余额" value="1"></el-option>
					<el-option label="对私充值余额" value="2"></el-option>
					<el-option label="收款余额" value="3"></el-option>
					<el-option label="支付宝" value="4"></el-option>
					<el-option label="微信" value="5"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="增加或减少">
				<el-select class="el_input" v-model="form.aos" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="不变" value="0"></el-option>
					<el-option label="增加" value="1"></el-option>
					<el-option label="减少" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注">
				<el-input class="el_input" v-model="form.mark" placeholder="备注搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="时间选择">
				<div class="block">
				<el-date-picker
					v-model="all_ra_time"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item">
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" style="background-color:#795A25;border: none;" @click="page_ser">查询</el-button>
				<el-button type="primary" style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="ser_para_clear">清空条件</el-button>
				<el-button type="primary" style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25" @click="all_ra_export">导出</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading" @selection-change="all_ra_choose">
				<el-table-column type="selection" width="39px"></el-table-column>
				<el-table-column label="流水编号" width="200px">
					<template slot-scope="scope">
						<span class="tab_line_item link" @click="to_ra_info(scope.row)">{{scope.row.ra_num}}</span>
					</template>
				</el-table-column>
				<el-table-column label="时间">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作人" width="230px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.act_user_info.name}}/{{scope.row.act_user_info.tel}}/{{scope.row.act_user_type_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="金额" width="230px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.aos_text}}/{{scope.row.much}}/{{scope.row.balance_type_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作后收入金额" prop="balance"></el-table-column>
				<el-table-column label="操作后对公金额" prop="recharge_balance"></el-table-column>
				<el-table-column label="操作后对私金额" prop="recharge_balance2"></el-table-column>
				<el-table-column label="备注" prop="mark"></el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[20, 30, 40, 50]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>
	</div>
</template>

<script>
import { Loading } from "element-ui";
	export default {
		data() {
			return {

				//搜索条件
				form: {		  
					ra_num:'',//货源编号
					act_type:'',//操作类型:(1:后台充值,2:支付宝充值,3:微信充值,4:实时单支付运费,5:补录单支付运费)
					act_user_type:'',//产生此流水记录的操作人类型(1:自己,2:其他app用户,3:后台人员)
					balance_type:'',//余额类型(1:recharge_balance,2:recharge_balance2,3:balance,4:ali_pay,5:wx_pay)	
					aos:'',//加或者减(0:不变,1:加,2减)(add or subtract)
					mark:'',//备注
					creat_time_start:'',//开始时间
					creat_time_end:'',//结束时间
				},
				all_ra_time:'',
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 20,
					p: 1,
				},
				all_choosed:'',//选中数据
				//快捷时间选择器
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
			}
		},
		created() {
			
			//读取页面数据
			this.get_page_data()
		},
		methods: {
			//导出
			all_ra_export(){
				//未勾选数据
				if(this.all_choosed.length == 0){
					this.$my.other.confirm({
						content:"当前未勾选数据,是否全部导出",
						confirm:()=>{
							//读取全部数据
							this.$my.net.req({
								data:{
									mod:'finance',
									ctr:'ra_list_by_shipper',
									...this.form,
									num:1000,
								},
								callback:(data)=>{

									//加载完毕
									this.list.loading=false

									//总数
									this.list.total=data.max

									//预处理
									for(let item of data.list){

										//时间
										item.creat_time_text=this.$my.other.totime(item.creat_time);

										//产生此流水记录的操作人类型(1:自己,2:其他app用户,3:后台人员)
										switch(item.act_user_type){
											case '1':item.act_user_type_text="本人操作";break;
											case '2':item.act_user_type_text="其他app用户";break;
											case '3':item.act_user_type_text="后台人员";break;
										}
										
										//余额类型(1:recharge_balance,2:recharge_balance2,3:balance,4:ali_pay,5:wx_pay)
										switch(item.balance_type){
											case '1':item.balance_type_text="对公充值余额";break;
											case '2':item.balance_type_text="对私充值余额";break;
											case '3':item.balance_type_text="收款余额";break;
											case '4':item.balance_type_text="支付宝";break;
											case '5':item.balance_type_text="微信";break;
										}

										//加或者减(0:不变,1:加,2减)(add or subtract)
										switch(item.aos){
											case '0':item.aos_text="不变";break;
											case '1':item.aos_text="增加";break;
											case '2':item.aos_text="减少";break;
										}
										item.name = item.act_user_info.name
										item.tel = item.act_user_info.tel
									}
									this.make_csv(data.list)
									Loading.service(this.options);
								}
							})
						}
					});
				}else{
					Loading.service(this.options);
					this.make_csv(this.all_choosed)
				}
			},
			//生成csv
			make_csv(data){
				var shunxu=[
					{key:"ra_num",cname:"流水编号"},
					{key:"creat_time_text",cname:"操作时间"},
					{key:"name",cname:"操作人"},
					{key:"tel",cname:"操作人电话"},
					{key:"act_user_type_text",cname:"操作人类型"},
					{key:"aos_text",cname:"金额变化"},
					{key:"much",cname:"本次操作金额"},
					{key:"balance",cname:"操作后收入金额"},
					{key:"recharge_balance",cname:"操作后对公金额"},
					{key:"recharge_balance2",cname:"操作后对私金额"},
					{key:"mark",cname:"备注"},
				],str="";
				for(var a in shunxu){
					str+=shunxu[a].cname+",";
				}
				str+="\n";
				for(var i=0;i<data.length;i++){
					for(var a in shunxu){
						str+=`${data[i][shunxu[a].key]}\t,`;
					}
					str+='\r\n';
				}

				str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				//生成下载链接
				let link = document.createElement('a');
				link.href = str;

				//对下载的文件命名
				link.download = "流水列表.csv";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				let loadingInstance = Loading.service(this.options);
					this.$nextTick(() => {
					loadingInstance.close();
				});
			},
			//选中数据
			all_ra_choose(event){
				this.all_choosed=event
			},
			//前往各种流水详情
			to_ra_info(item){

				//根据操作类型前往不同页面(操作类型:(1:后台充值,2:支付宝充值,3:微信充值,4:实时单支付运费,5:补录单支付运费))
				switch(item.act_type){
					case '1':
						var path='/pages/user_info/recharge_list?ra_num='+item.ra_num;
						break;
					case '4':
						var path='/pages/tord_real_time/tord_pay_list?this_pay_shipper_ra_num='+item.ra_num;
						break;
					case '6':
						var path='/pages/tord_real_time/tord_pay_list?this_refund_shipper_ra_num='+item.ra_num;
						break;
				}

				this.$router.push({
					path:path,
				});
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					ra_num:'',//货源编号
					act_type:'',//操作类型:(1:后台充值,2:支付宝充值,3:微信充值,4:实时单支付运费,5:补录单支付运费)
					act_user_type:'',//产生此流水记录的操作人类型(1:自己,2:其他app用户,3:后台人员)
					balance_type:'',//余额类型(1:recharge_balance,2:recharge_balance2,3:balance,4:ali_pay,5:wx_pay)	
					aos:'',//加或者减(0:不变,1:加,2减)(add or subtract)
					mark:'',//备注
					creat_time_start:'',
					creat_time_end:''
				}
				this.all_ra_time=''
				this.page.p = 1
				this.get_page_data()
			},

			//搜索
			page_ser(){
				//时间处理
				if (this.all_ra_time) {
					this.form.creat_time_start = parseInt(this.all_ra_time[0] / 1000);
					this.form.creat_time_end= parseInt(this.all_ra_time[1] / 1000);
				} else {
					this.form.creat_time_start = "";
					this.form.creat_time_end = "";
				}
				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'finance',
						ctr:'ra_list_by_shipper',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//产生此流水记录的操作人类型(1:自己,2:其他app用户,3:后台人员)
							switch(item.act_user_type){
								case '1':item.act_user_type_text="本人操作";break;
								case '2':item.act_user_type_text="其他app用户";break;
								case '3':item.act_user_type_text="后台人员";break;
							}
							
							//余额类型(1:recharge_balance,2:recharge_balance2,3:balance,4:ali_pay,5:wx_pay)
							switch(item.balance_type){
								case '1':item.balance_type_text="对公充值余额";break;
								case '2':item.balance_type_text="对私充值余额";break;
								case '3':item.balance_type_text="收款余额";break;
								case '4':item.balance_type_text="支付宝";break;
								case '5':item.balance_type_text="微信";break;
							}

							//加或者减(0:不变,1:加,2减)(add or subtract)
							switch(item.aos){
								case '0':item.aos_text="不变";break;
								case '1':item.aos_text="增加";break;
								case '2':item.aos_text="减少";break;
							}
							item.name = item.act_user_info.name
							item.tel = item.act_user_info.tel
						}
	
						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 18%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin-left: 0;
	}
	.link{
		cursor:pointer;
		color:blue;
		border-bottom:1px solid blue;
	}
</style>